<template>
  <a-spin :spinning="page_loading">
    <sdPageHeader title="Müşteri Hareketleri" />
    <Main>
      <Pstates class="pst">
        <div
          class="growth-upward"
          role="button"
        >
          <p>Desi</p>
          <sdHeading as="h1">
            {{ details.total_approved_cbm }}
            <sub class="sub">
              <span> <sdFeatherIcons
                type="arrow-up"
                size="14"
              /> Onaylandı </span>
            </sub>
          </sdHeading>
        </div>
        <div
          class="growth-upward"
          role="button"
        >
          <p>Fiyat</p>
          <sdHeading as="h1">
            {{ details.total_approved_price }}
            <sub class="sub">
              <span> <sdFeatherIcons
                type="arrow-up"
                size="14"
              /> Onaylandı </span>
            </sub>
          </sdHeading>
        </div>
        <div
          class="growth-downward"
          role="button"
        >
          <p>Desi</p>
          <sdHeading as="h1">
            {{ details.total_un_approved_cbm }}
            <sub class="sub">
              <span> <sdFeatherIcons
                type="arrow-down"
                size="14"
              /> Onay Bekliyor </span>
            </sub>
          </sdHeading>
        </div>
        <div
          class="growth-downward"
          role="button"
        >
          <p>Fiyat</p>
          <sdHeading as="h1">
            {{ details.total_un_approved_price }}
            <sub class="sub">
              <span> <sdFeatherIcons
                type="arrow-down"
                size="14"
              /> Onay Bekliyor </span>
            </sub>
          </sdHeading>
        </div>
        <div
          class="growth-upward"
          role="button"
        >
          <p>Desi</p>
          <sdHeading as="h1">
            {{ details.total_cbm }}
            <sub class="sub">
              <span> <sdFeatherIcons
                icon="arrow-right"
                size="14"
              /> Toplam </span>
            </sub>
          </sdHeading>
        </div>
        <div
          class="growth-upward"
          role="button"
        >
          <p>Fiyat</p>
          <sdHeading as="h1">
            {{ details.total_price }}
            <sub class="sub">
              <span> <sdFeatherIcons
                icon="arrow-right"
                size="14"
              /> Toplam </span>
            </sub>
          </sdHeading>
        </div>
      </Pstates>

      <a-row
        type="flex"
        justify="center"
        :gutter="16"
        align="top"
      >
        <a-col :span="24">
          <sdCards headless>
            <a-table
              row-key="id"
              :data-source="data"
              :columns="columns"
            >
              <template #filterIcon="filtered">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
              </template>
              <template #approved_price="{record}">
                {{ record.approved_price }} ₺
              </template>
              <template #un_approved_price="{record}">
                {{ record.un_approved_price }} ₺
              </template>
              <template #detail_render="{record}">
                <a-button
                  type="primary"
                  :loading="receipt_list_loadings[record.id] === true"
                  @click="detailCargoModalClick(record.id)"
                >
                  Detay
                </a-button>
              </template>
            </a-table>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </a-spin>
  <a-modal
    v-model:visible="show_invoice_list"
    title="Fatura Listesi"
    :footer="null"
    @cancel="show_invoice_list = false"
  >
    <a-table
      :columns="invoice_columns"
      :data-source="invoice_items"
    >
      <template #detail_render="{ record }">
        <a-button
          type="primary"
          @click="goDetailCargo(record.id)"
        >
          Mutabık Sayfası
        </a-button>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'
import { Pstates } from '@/view/dashboard/style'

export default defineComponent({
  components: {
    SearchOutlined,
    Pstates,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      page_loading: false,
      receipt_list_loadings: [],

      show_invoice_list: false,

      data: [],
      filterDate: '',
      selectedCustomerId: '',
      invoice_items: [],
      details: {
        unApprovedCbm: 0,
        unApprovedPrice: 0,
        approvedCbm: 0,
        approvedPrice: 0,
        totalCbm: 0,
        totalPrice: 0,
      },
      userId: '',
      invoice_columns: [
        {
          title: 'Fatura No',
          dataIndex: 'title',
          align: 'center',
        },
        {
          title: 'Detay',
          align: 'center',
          slots: {
            customRender: 'detail_render',
          },
        },
      ],
      columns: [
        {
          title: 'Müşteri Adı',
          dataIndex: 'user_name',
          align: 'center',
          slots: {
            customRender: 'user_name',
          },
        }, {
          title: 'Onaylanan Fiyat',
          dataIndex: 'approved_price',
          align: 'right',
          slots: {
            customRender: 'approved_price',
          },
        }, {
          title: 'Onaylanmayan Fiyat',
          dataIndex: 'un_approved_price',
          align: 'right',
          slots: {
            customRender: 'un_approved_price',
          },
        }, {
          title: 'Detay',
          align: 'center',
          slots: {
            customRender: 'detail_render',
          },
        },
      ],
    }
  },
  mounted() {
    this.userId = this.$route.params.userId
    this.get()
  },
  methods: {
    goDetailCargo(invoiceId) {
      this.$router.push({
        name: 'CustomerSelectedDetail.Page',
        params: { invoice_id: invoiceId, customer_id: this.selectedCustomerId },
      })
    },
    detailCargoModalClick(customerId) {
      this.receipt_list_loadings[customerId] = true
      this.$restMethods.postForNoc('/get-invoices-for-customer/' + customerId).then(response => {
        if ( ! response.hasError()) {
          this.invoice_items = response.getData().items

          this.selectedCustomerId = customerId
          this.show_invoice_list = true
        }
        this.receipt_list_loadings[customerId] = false
      })
    },
    get() {
      this.page_loading = true
      this.$restMethods.postForNoc('/customer-movements').then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()

          this.data = data.items

          this.details.total_approved_price = data.details.total_approved_price
          this.details.total_approved_cbm = data.details.total_approved_cbm
          this.details.total_un_approved_price = data.details.total_un_approved_price
          this.details.total_un_approved_cbm = data.details.total_un_approved_cbm
          this.details.total_price = data.details.total_price
          this.details.total_cbm = data.details.total_cbm

        }
        this.page_loading = false
      })
    },
  },
})
</script>
<style>

hr {
  border-color: #ededed;
  border-top: none;
  border-left: none;
  border-right: none;
}

.pst {
  display: flex;
  justify-content: space-between; /* öğeler arasında eşit boşluk bırakır */
  align-items: center;
  padding: 0 20px; /* Sağdan ve soldan biraz boşluk eklemek için, dilerseniz bu değeri değiştirebilirsiniz */
}

.pst > div {
  flex: 1; /* Her bir öğe için eşit genişlik alır */
  text-align: center; /* İçerikleri merkezler */
}

.sub {
  display: block;
}
</style>
